import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// react-redux
import { setUserData } from 'store/features/userSlice';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';

// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { setMenu } from 'store/features/customizationSlice';
import bottomRightBrand from 'assets/images/brand/bottom_brand.png';

// assets
import { IconChevronRight } from '@tabler/icons-react';

// utils
import callAzureFunctionPublic from 'utils/call-azure-function-public';
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`
  })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  const userData = useSelector((state) => state.user?.data);
  const dispatch = useDispatch();

  const [accessRoleWebSocket, setAccessRoleWebSocket] = useState(null);

  const handleLeftDrawerToggle = () => {
    dispatch(setMenu({ opened: !leftDrawerOpened }));
  };

  useEffect(() => {
    dispatch(setMenu({ opened: !matchDownMd }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const handleAccessRoleOpenWS = () => {
    console.info('AR WebSocket: Connection opened');
  };

  const handleAccessRoleCloseWS = () => {
    console.info('AR WebSocket: Connection closed');
  };

  const handleAccessRoleErrorWS = (event) => {
    console.error('AR WebSocket: Error', event);
  };

  const handleAccessRoleMessageWS = async () => {
    let newUserAccessRoles = [];

    const res = await callAzureFunction({
      url: '/access-roles/by-user',
      method: 'get'
    });
    newUserAccessRoles = res.data;

    // Update user/users access roles in local storage
    dispatch(
      setUserData({
        data: { ...userData, AccessRoles: newUserAccessRoles }
      })
    );
  };

  const userPermissionWebSocketConnection = useCallback(async () => {
    let socket;
    try {
      const result = await callAzureFunctionPublic({
        url: `/websocket_connect_access_roles`,
        method: 'get'
      });
      const wsUrl = result.data.webSocketUrl;
      if (wsUrl) {
        socket = new WebSocket(wsUrl);

        socket.addEventListener('open', () => handleAccessRoleOpenWS);
        socket.addEventListener('close', () => handleAccessRoleCloseWS);
        socket.addEventListener('error', () => handleAccessRoleErrorWS);
        socket.addEventListener('message', (event) => handleAccessRoleMessageWS(event));

        setAccessRoleWebSocket(socket);
      }
    } catch (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const webSocketDisconnect = () => {
    if (accessRoleWebSocket) {
      console.info('WebSocket: Disconnecting');
      accessRoleWebSocket.removeEventListener('open', handleAccessRoleOpenWS);
      accessRoleWebSocket.removeEventListener('close', handleAccessRoleCloseWS);
      accessRoleWebSocket.removeEventListener('error', handleAccessRoleErrorWS);
      accessRoleWebSocket.removeEventListener('message', handleAccessRoleMessageWS);
      accessRoleWebSocket.close();
      setAccessRoleWebSocket(null);
    }
  };

  useEffect(() => {
    userPermissionWebSocketConnection();
    return () => webSocketDisconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionWebSocketConnection]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
          }}
        >
          <Toolbar sx={{ height: 80, borderBottom: 1, borderColor: 'grey.200' }}>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

        {/* main content */}
        <Main theme={theme} open={leftDrawerOpened}>
          {/* breadcrumb */}
          <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
          <Outlet />
          {/* Brand */}
          <Box sx={{ width: 'fit-content', mt: 3, ml: 'auto' }}>
            <img src={bottomRightBrand} alt="bottom right brand" />
          </Box>
        </Main>
        {/* <Customization /> */}
      </Box>
    </>
  );
};

export default MainLayout;
